var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[(_vm.id)?_c('span',[_vm._v(_vm._s(_vm.l('Edit')))]):_vm._e(),(!_vm.id)?_c('span',[_vm._v(_vm._s(_vm.l('Create')))]):_vm._e()])]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":_vm.l('WebSiteNoticeTitle')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'title',
                    {
                        rules: [
                            {
                                required: true,
                                message: _vm.l('ThisFieldIsRequired'),
                            }
                        ],
                    } ]),expression:"[\n                    'title',\n                    {\n                        rules: [\n                            {\n                                required: true,\n                                message: l('ThisFieldIsRequired'),\n                            }\n                        ],\n                    },\n                    ]"}],attrs:{"placeholder":_vm.l('WebSiteNoticeTitleInputDesc')}})],1),_c('a-form-item',{attrs:{"label":_vm.l('WebSiteNoticeContent')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'content',
                    {
                        rules: [
                            {
                                required: true,
                                message: _vm.l('ThisFieldIsRequired'),
                            }
                        ],
                    } ]),expression:"[\n                    'content',\n                    {\n                        rules: [\n                            {\n                                required: true,\n                                message: l('ThisFieldIsRequired'),\n                            }\n                        ],\n                    },\n                    ]"}],attrs:{"placeholder":_vm.l('WebSiteNoticeContentInputDesc'),"auto-size":{ minRows: 3, maxRows: 5 },"maxLength":"3000"}})],1),_c('a-form-item',{attrs:{"label":_vm.l('ViewCount')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'viewCount',
                    {
                        rules: [
                        ],
                    } ]),expression:"[\n                    'viewCount',\n                    {\n                        rules: [\n                        ],\n                    },\n                    ]"}],attrs:{"min":0,"max":1000000,"step":1}})],1),_c('a-form-item',{staticClass:"btn--container"},[_c('a-button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.l('Save'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }